import React from "react";
import Loader from "../Loading/Loader";

type ButtonProps = {
  title: string;
  buttonType: "outline" | "primary" | "text" | "secondary" | "download";
  textStyles?: string;
  testID?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  secondValue?: string;
  alignStart?: boolean;
  styles?: string;
  loading?: boolean;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "buttonType">;

const getBaseClasses = () => `
flex
w-full
rounded-2xl
font-medium
transition
items-center
text-base
lg:leading-6
h-60
`;

const getClassesByType = (
  type: "outline" | "primary" | "text" | "secondary" | "download",
  disabled: boolean
) => {
  if (disabled) {
    return `
      ${getBaseClasses()}
      bg-[#EEEEEE]  // Disabled button background
      text-[#BBBBBB]  // Disabled button text
      cursor-not-allowed
      border-none
    `;
  }

  switch (type) {
    case "primary":
      return `
            ${getBaseClasses()}
            bg-primary
            text-secondary
            p-12
            border-none
            lg:hover:bg-hover
        `;
    case "secondary":
      return `
            ${getBaseClasses()}
            bg-secondary
            text-primary
            p-12
            border-none
            lg:hover:bg-[#D5D8F4]
        `;
    case "download":
      return `
            ${getBaseClasses()}
            bg-black
            p-1.5
            border-none
            
            `;
    case "text":
      return `
            ${getBaseClasses()}
            bg-none
            text-light-grey-4
            border-none
        `;
    case "outline":
      return `
            ${getBaseClasses()}
            bg-white
            text-dark-grey
            p-12
            rounded-2xl 
            border-light-grey-3 
            border-solid 
            border
            text-dark-grey
        `;

    default:
      return getBaseClasses();
  }
};

const Button = ({
  title,
  buttonType = "primary",
  textStyles = "",
  icon,
  disabled = false,
  onClick,
  secondValue = "",
  alignStart = false,
  styles,
  testID,
  className,
  loading,
  ...rest
}: ButtonProps) => {
  const classNames = getClassesByType(buttonType, disabled);
  return (
    <button
      className={`${className} ${classNames} ${textStyles} ${
        secondValue && "justify-between"
      } ${
        alignStart ? "justify-start text-start" : "justify-center"
      } ${styles}`}
      onClick={onClick}
      disabled={disabled || loading}
      id={`${testID}-btn`}
      {...rest}
    >
      {loading ? (
        <Loader
          width={24}
          height={24}
          colorWhite={buttonType === "primary" && true}
        />
      ) : (
        <>
          {icon && <span className="mr-10">{icon}</span>}
          {title}
          {secondValue && <span>{secondValue}</span>}
        </>
      )}
    </button>
  );
};

export default Button;
