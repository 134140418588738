import TextWithBackButton from "../components/TextWithBackButton";
import { Form, Formik } from "formik";
import InputField from "../components/InputField/InputField";
import AuthContainer from "../components/AuthContainer/AuthContainer";
// import DropdownWithSearch from "../components/DropdownWithSearch/DropdownWithSearch";
import Typography from "../components/Typography/Typography";
import Divider from "../components/Divider/Divider";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button/Button";
import GridLGHidden from "../components/GridComponent/components/GridLGHidden";
import Header from "../components/Header/Header";
import { SignUpSchema } from "../scemas/validations";
import useCreateWithAdmin from "../store/Mutations/organisation-useCreateWithAdmin";
import { ICreateWithAdmin } from "../store/interface";
import { useToast } from "../context/ToastProvider";
import { useLayoutEffect } from "react";

const initalValue: ICreateWithAdmin = {
  organization: {
    organizationName: "",
    b2bAdmin: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
  },
};

const SignUp = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { mutate, isPending } = useCreateWithAdmin({
    onSuccess: (data) => {
      window.localStorage.setItem(
        "checkoutOrganisationID",
        data.organization.id
      );
      window.localStorage.setItem(
        "checkoutOrganisationLicense",
        data.organization.totalLicenses.toString()
      );
      // window.localStorage.setItem(
      //   "checkoutSelectedCountry",
      //   data.organization.country.toString()
      // );
      navigate("/checkout");
    },
    onError: (error: any) => {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      addToast({
        message: errorMessage,
        type: "error",
      });
    },
  });

  // const [countriesList, setCountriesList] = useState<Country[]>([]);
  // const [stateList, setStateList] = useState<State[]>([]);
  // const [selectedCountryId, setSelectedCountryId] = useState<string | null>(
  //   null
  // );

  // useEffect(() => {
  //   setCountriesList(countries);
  // }, []);

  // useEffect(() => {
  //   if (selectedCountryId === "233") {
  //     setStateList(states);
  //   } else {
  //     setStateList([]);
  //   }
  // }, [selectedCountryId]);

  // const countryOptions = countriesList.map((country) => ({
  //   value: String(country.id),
  //   label: country.name,
  // }));

  // const stateOptions = stateList.map((state) => ({
  //   value: state.state_code,
  //   label: state.name,
  // }));

  useLayoutEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    metaThemeColor && metaThemeColor.setAttribute('content', '#fbfbfe');
  }, []);

  return (
    <AuthContainer>
      <div className="lg:rounded-20  w-full bg-light-grey-5  lg:bg-white  lg:max-h-[90svh] lg:h-auto   px-12 pb-32 lg:p-24">
        <div className=" lg:max-h-[calc(90svh-48px)]  lg:overflow-auto lg:pr-12">
        <GridLGHidden>
          <Header />
        </GridLGHidden>
        <TextWithBackButton text="Signup" backBtnClick={() => navigate("/")} />
        <Formik
          initialValues={{ ...initalValue, repeatPassword: "" }}
          validationSchema={SignUpSchema}
          validateOnBlur={true}
          validateOnChange={false}
          onSubmit={(values) => {
            mutate({...values, organization: {organizationName: values.organization.organizationName,
              b2bAdmin: {
                firstName: values.organization.b2bAdmin.firstName,
                lastName: values.organization.b2bAdmin.lastName,
                email: values.organization.b2bAdmin.email.toLocaleLowerCase(),
                password: values.organization.b2bAdmin.password,
              }}});
          }}
        >
          {({ errors, setFieldValue, handleBlur, values, touched }) => {
            return (
              <Form className="mt-24 lg:mt-72  w-full flex flex-col gap-20 lg:gap-24">
                <InputField
                  label="First name"
                  required
                  placeholder="First name"
                  id="first-name"
                  value={values.organization.b2bAdmin.firstName}
                  onBlur={handleBlur("organization.b2bAdmin.firstName")}
                  onChange={(e) => {
                    setFieldValue(
                      `organization.b2bAdmin.firstName`,
                      e.target.value
                    );
                  }}
                  error={
                    touched.organization?.b2bAdmin?.firstName &&
                    errors.organization?.b2bAdmin?.firstName
                      ? errors.organization.b2bAdmin.firstName
                      : ""
                  }
                />
                <InputField
                  label="Last name"
                  required
                  placeholder="Last name"
                  id="last-name"
                  value={values.organization?.b2bAdmin?.lastName}
                  onBlur={handleBlur("organization.b2bAdmin.lastName")}
                  onChange={(e) =>
                    setFieldValue(
                      "organization.b2bAdmin.lastName",
                      e.target.value
                    )
                  }
                  error={
                    touched.organization?.b2bAdmin?.lastName &&
                    errors.organization?.b2bAdmin?.lastName
                      ? errors.organization.b2bAdmin.lastName
                      : ""
                  }
                />
                <InputField
                  label="Organization name"
                  required
                  placeholder="Organization name"
                  id="organization-name"
                  value={values.organization.organizationName}
                  onBlur={handleBlur("organization.organizationName")}
                  onChange={(e) =>
                    setFieldValue(
                      "organization.organizationName",
                      e.target.value
                    )
                  }
                  error={
                    touched.organization?.organizationName &&
                    errors.organization?.organizationName
                      ? errors.organization.organizationName
                      : ""
                  }
                />
                {/* <InputField
                  label="Billing address"
                  required
                  placeholder="Billing address"
                  id="billing-address"
                  value={values.organization.billingAddress}
                  onBlur={handleBlur("organization.billingAddress")}
                  onChange={(e) =>
                    setFieldValue("organization.billingAddress", e.target.value)
                  }
                  error={
                    touched.organization?.billingAddress &&
                    errors.organization?.billingAddress
                      ? errors.organization.billingAddress
                      : ""
                  }
                /> */}
                {/* <div>
                  <Typography type="Body2" className="mb-7 lg:mb-24">
                    Country
                  </Typography>
                  <DropdownWithSearch
                    options={countryOptions}
                    required={true}
                    value={values.organization.country}
                    onChange={(e) => {
                      const country = e?.label || "";
                      setFieldValue("organization.country", country);
                      setSelectedCountryId(country);
                    }}
                    error={
                      touched.organization?.country &&
                      errors.organization?.country
                        ? errors.organization.country
                        : ""
                    }
                    onBlur={handleBlur("organization.country")}
                  />
                </div> */}
                {/* {selectedCountryId === "233" && (
                  <div>
                    <Typography type="Body2" className="mb-7 lg:mb-24">
                      State
                    </Typography>
                    <DropdownWithSearch
                      options={stateOptions}
                      value={values.organization.state}
                      onChange={(e) =>
                        setFieldValue("organization.state", e?.value)
                      }
                    />
                  </div>
                )} */}
                {/* <InputField
                  label="City"
                  required
                  placeholder="City"
                  id="city"
                  value={values.organization.city}
                  onBlur={handleBlur("organization.city")}
                  onChange={(e) =>
                    setFieldValue("organization.city", e.target.value)
                  }
                  error={
                    touched.organization?.city && errors.organization?.city
                      ? errors.organization.city
                      : ""
                  }
                /> */}
                {/* <InputField
                  label="Postal code"
                  required
                  placeholder="Postal code"
                  id="postal-code"
                  value={values.organization.postalCode}
                  onBlur={handleBlur("organization.postalCode")}
                  onChange={(e) =>
                    setFieldValue("organization.postalCode", e.target.value)
                  }
                  error={
                    touched.organization?.postalCode &&
                    errors.organization?.postalCode
                      ? errors.organization.postalCode
                      : ""
                  }
                /> */}
                <InputField
                  type="email"
                  label="Email address"
                  required
                  placeholder="Email address"
                  id="email-address"
                  value={values.organization.b2bAdmin.email}
                  onBlur={handleBlur("organization.b2bAdmin.email")}
                  onChange={(e) =>
                    setFieldValue("organization.b2bAdmin.email", e.target.value)
                  }
                  error={
                    touched.organization?.b2bAdmin?.email &&
                    errors.organization?.b2bAdmin?.email
                      ? errors.organization.b2bAdmin.email
                      : ""
                  }
                />
                <InputField
                  type="password"
                  label="Password"
                  required
                  placeholder="Password"
                  id="password"
                  passwordWithIcon={true}
                  passwordText={"8 characters"}
                  value={values.organization.b2bAdmin.password}
                  onBlur={handleBlur("organization.b2bAdmin.password")}
                  onChange={(e) =>
                    setFieldValue(
                      "organization.b2bAdmin.password",
                      e.target.value
                    )
                  }
                  error={
                    touched.organization?.b2bAdmin?.password &&
                    errors.organization?.b2bAdmin?.password
                      ? errors.organization.b2bAdmin.password
                      : ""
                  }
                />
                <InputField
                  type="password"
                  label="Repeat password"
                  required
                  placeholder="Password"
                  id="repeat-password"
                  passwordWithIcon={true}
                  passwordText={"Passwords must match"}
                  value={values.repeatPassword}
                  onBlur={handleBlur("repeatPassword")}
                  onChange={(e) =>
                    setFieldValue("repeatPassword", e.target.value)
                  }
                  error={
                    touched.repeatPassword && errors.repeatPassword
                      ? errors.repeatPassword
                      : ""
                  }
                />
                {/* <InputField
                  type="number"
                  label="How many licenses would you like to purchase ?"
                  required
                  placeholder="10"
                  value={values.organization.totalLicenses}
                  onChange={(e) =>
                    setFieldValue("organization.totalLicenses", +e.target.value)
                  }
                /> */}
                {/* <div>
                  <Typography type="Body2" className="mb-7 lg:mb-24">
                    Payment method
                  </Typography>
                  <label
                    className={`border-light-grey-3 focus:outline-primary rounded-12  flex cursor-pointer  items-center gap-12 border px-12 py-16`}
                  >
                    <input type="radio" value="card" checked />
                    <Typography type="Body2">Card</Typography>
                   <img src={Visa} alt="" />
                    <img src={Mastercard} alt="" />
                    <img src={AmericanExpress} alt="" />
                    <img src={Apple} alt="" />
                  </label>
                </div> 
                */}
                <Divider marginY="my-0" />
                <Typography type="Body2" className=" !text-[#BBBBBB]">
                  By signing up, you accept our{" "}
                  <Link
                    to={"https://talkpal.ai/terms-and-conditions/"}
                    target="_blank"
                    className="underline underline-offset-4 "
                  >
                    Terms and Conditions
                  </Link>{" "}
                  and {" "}
                  <Link
                    to={"https://talkpal.ai/privacy-policy/"}
                    target="_blank"
                    className="underline underline-offset-4 "
                  >
                    {" "}
                    Privacy Policy.
                  </Link>{" "}
                  Occasionally, we’ll send you our newsletters, with learning tips and special offers. You can unsubscribe anytime.
                </Typography>
                <Button
                  buttonType="primary"
                  type="submit"
                  testID="signup"
                  loading={isPending}
                  title={"Continue"}
                />
                <Divider marginY="my-0" />
                <Typography
                  type="Body2"
                  className="text-center !text-[#BBBBBB]  "
                >
                  Already have an account?{" "}
                  <Link
                    to={"/login"}
                    className="text-primary underline underline-offset-4 ml-[3px]"
                    id="alreadyhaveAccount"
                  >
                    Log in
                  </Link>
                </Typography>
              </Form>
            );
          }}
        </Formik>
        </div>
      </div>
    </AuthContainer>
  );
};

export default SignUp;
