import { BrowserRouter, Routes } from "react-router-dom";
import { useEffect } from "react";
import useOrganisation from "../store/Querys/useOrganisation";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "../store/keys";
import AuthRoutes from "./AuthRoutes";

const Router = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  const queryClient = useQueryClient();
  const enabled = accessToken !== null;

  useOrganisation({
    enabled: enabled,
  });

  useEffect(() => {
    if (!accessToken) {
      queryClient.removeQueries({
        queryKey: [QueryKeys.Organisation],
      });
    }
  }, [accessToken, queryClient]);

  return (
    <BrowserRouter>
      <AuthRoutes />
      <Routes>{/* <Route path="*" element={<>404</>} /> */}</Routes>
    </BrowserRouter>
  );
};

export default Router;
