import React, { useEffect, useLayoutEffect, useState } from "react";
import GridComponent from "../components/GridComponent/GridComponent";
import { LeftSideComponent } from "../components/Sidebar/Sidebar";
import GridTitle from "../components/GridComponent/components/GridTitle";
import Typography from "../components/Typography/Typography";
import EditIcon from "../assets/icons/bx-edit.svg";
import EditIconBlue from "../assets/icons/bx-edit-blue.svg";
import { RightSideComponent } from "../components/GridComponent/GridRightComponents/GridViewComponents";
import GridLGHidden from "../components/GridComponent/components/GridLGHidden";
import Header from "../components/Header/Header";
import ResendIcon from "../assets/icons/mdi_email-resend-outline-blue.svg";
import ResendIconBlack from "../assets/icons/mdi_email-resend-outline-black.svg";
import TrashIcon from "../assets/icons/bx-trash-red.svg";
import StudentListItem from "../components/StudentListItem/StudentListItem";
import BottomNav from "../components/BottomNav/BottomNav";
import Button from "../components/Button/Button";
import GridSMHidden from "../components/GridComponent/components/GridSMHidden";
import { useLocation, useNavigate } from "react-router-dom";
import BottomSheetModal from "../components/BottomSheetModalContainer/BottomSheetModalContainer";
import useInvitedStudents from "../store/Querys/useInvitations";
import useOrganisation from "../store/Querys/useOrganisation";
import { IInvitedStudent } from "../store/interface";
import useDeleteInvitations from "../store/Mutations/useDeleteInvitations";
import useSendInvitations from "../store/Mutations/useSendInvitation";
import ModalComponent from "../components/modals/ModalComponent";
import { useToast } from "../context/ToastProvider";
import useSingleOrganization from "../store/Querys/useSingleOrganization";
import { useQueryClient } from "@tanstack/react-query";
import SubscriptionDetailsModal from "../components/StudentsComponents/SubscriptionDetailsModal";

const INACTIVE_STATUSES = [
  "Invitation Pending",
  "Registration Pending",
  "Inactive",
];

const Students = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };
  const [dropdownSettings, setDropdownSettings] = useState<string>("");
  const [editable, setEditable] = useState(false);
  const [status, setStatus] = useState<
    "Invitation Pending" | "Registration Pending" | "Active" | "Inactive"
  >("Active");
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
  const [moreSettings, setMoreSettings] = React.useState<string>("");
  const [deleteStudentsModal, setDeleteStudentsModal] = useState(false);
  // const [resendInvitationModal, setResendInvitationModal] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const isPending = queryParams.get("pending");
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [inactiveAccount, setInactiveAccount] = useState(false);
  const [subscriptionDetailsModal, setSubscriptionDetailsModal] =
    useState(false);
  const queryClient = useQueryClient();

  const sendInvitationsMutation = useSendInvitations();

  const { addToast } = useToast();

  const { data: organizationsData } = useOrganisation();
  const orgId = organizationsData?.organizations?.[0]?.id || "";
  
  useEffect(()=>{
    if(organizationsData){
      setInactiveAccount(false);
    }
  },[organizationsData])

  useEffect(() => {
    const queryParams = getQueryParams(location.search);

    if (queryParams.get("failed") === "true") {
      setPaymentFailed(true);
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = getQueryParams(location.search);

    if (
      organizationsData &&
      !organizationsData?.active &&
      queryParams.get("failed") !== "true"
    ) {
      setInactiveAccount(true);
    }
  }, [organizationsData?.active, location.search]);

  const { data: students, refetch: refetchStudents } = useInvitedStudents(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  useEffect(() => {
    isPending && setStatus("Inactive");
  }, [isPending]);
  const {
    data: singleOrganizationData,
    refetch: refechSingleOrganizationData,
  } = useSingleOrganization(orgId);

  const handleSelectAll = () => {
    if (Array.isArray(students) && students.length !== 0) {
      const selected = students
        .filter((student) =>
          status === "Active"
            ? student.status === "Active"
            : INACTIVE_STATUSES.includes(student.status)
        )
        .map((student) => student.email);
      setSelectedStudents(selected);
    }
  };

  const { mutate: deleteInvitations } = useDeleteInvitations({
    onSuccess: () => {
      setSelectedStudents([]);
      refetchStudents();
      refechSingleOrganizationData();
    },
    onError: (error) => {
      console.error("Error deleting invitations:", error);
    },
  });

  const handleDeleteInvitations = () => {
    deleteInvitations({
      orgId: orgId || "",
      emails: selectedStudents,
    });
    setSelectedStudents([]);
    setEditable(false);
    refetchStudents();
  };

  const handleInviteClick = () => {
    if (selectedStudents.length > 0 && orgId) {
      sendInvitationsMutation.mutate({
        emails: selectedStudents,
        orgId,
      });
      addToast({
        message: "Invitation sent successfully!",
        type: "success",
      });
    }
  };

  useLayoutEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    metaThemeColor && metaThemeColor.setAttribute('content', '#F5F5FC');
  }, []);

  return (
    <div className="flex flex-col lg:flex-row bg-[#F5F5FC] lg:bg-white">
      <GridLGHidden>
        <Header
        backgroundColor="[#F5F5FC]"
          editIcon={students?.length ? true : false}
          editIconClick={() => setEditable(!editable)}
          allStudentsSelected={
            students?.filter((el) => el.status === status).length ===
            selectedStudents.length
          }
          editable={editable}
          handleSelectAll={() => {
            students?.filter((el) => el.status === status).length ===
            selectedStudents.length
              ? setSelectedStudents([])
              : handleSelectAll();
          }}
        />
      </GridLGHidden>
      <MoreOptionsModal
        status={status}
        onClickDelete={() => {
          setDeleteStudentsModal(true);
          setMoreSettings("");
        }}
        onResendClick={() => {
          setMoreSettings("");
          handleInviteClick();
        }}
        isOpen={!!moreSettings}
        setOpen={(e) => {
          if (!e) setMoreSettings("");
        }}
      />
      <GridComponent
        dropdownSettings={dropdownSettings}
        rightSideComponent={
          <RightSideComponent
            onSuccess={() => {
              refetchStudents();
              setStatus("Inactive");
            }}
            singleOrganizationData={singleOrganizationData}
            refetch={refechSingleOrganizationData}
          />
        }
        leftSideComponent={<LeftSideComponent />}
        mainComponentClassNames={`flex-row max-lg:pb-100 ${
          dropdownSettings ? "outline-transparent" : ""
        }`}
        mainComponent={
          <MainComponent
            setDropdownSettings={setDropdownSettings}
            dropdownSettings={dropdownSettings}
            editable={editable}
            setEditable={setEditable}
            status={status}
            setStatus={setStatus}
            selectedStudents={selectedStudents}
            setSelectedStudents={setSelectedStudents}
            handleSelectAll={handleSelectAll}
            setMoreSettings={setMoreSettings}
            students={students || []}
            handleDeleteInvitations={handleDeleteInvitations}
            handleInviteClick={handleInviteClick}
            setDeleteStudentsModal={setDeleteStudentsModal}
          />
        }
      />
      <GridLGHidden>
        <div className="px-12 fixed bottom-80 w-full py-20 bg-[#F5F5FC]">
          <Button
            buttonType="primary"
            title="Manage licenses"
            onClick={() => navigate("/students/licenses")}
          />
        </div>
        <BottomNav />
      </GridLGHidden>

      <ModalComponent
        type={"delete"}
        setOpen={setDeleteStudentsModal}
        open={deleteStudentsModal}
        title={`Are you sure?`}
        description={`Deleted users will no longer have access to premium features and their progress will be lost.`}
        primaryButtonText={"Delete"}
        withCancel={true}
        cancelButtonText={"Cancel"}
        onCancel={() => {
          setDeleteStudentsModal(false);
        }}
        onSubmit={() => {
          handleDeleteInvitations();
          setDeleteStudentsModal(false);
          refetchStudents();
        }}
      />
      <ModalComponent
        type={"warning"}
        setOpen={setPaymentFailed}
        open={paymentFailed}
        title={"Payment failed"}
        description={"Please try again or you can contact support."}
        primaryButtonText={"Retry"}
        withCancel={true}
        cancelButtonText={"Log out"}
        onCancel={() => {
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("refreshToken");
          queryClient.removeQueries();
          navigate("/login");
        }}
        onSubmit={() => {
          setPaymentFailed(false);
          setSubscriptionDetailsModal(true);
        }}
      />
      <ModalComponent
        type={"warning"}
        setOpen={setInactiveAccount}
        open={inactiveAccount}
        title={"Inactive account"}
        description={"Please purchase licenses or contact support."}
        primaryButtonText={"Purchase licenses"}
        withCancel={true}
        cancelButtonText={"Log out"}
        onCancel={() => {
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("refreshToken");

          queryClient.removeQueries();
          navigate("/login");
        }}
        onSubmit={() => {
          setInactiveAccount(false);
          setSubscriptionDetailsModal(true);
        }}
      />
      {subscriptionDetailsModal && (
        <SubscriptionDetailsModal
          backBtnClick={() => {
            const queryParams = getQueryParams(location.search);

            if (queryParams.get("failed") === "true") {
              setPaymentFailed(true);
            } else {
              setInactiveAccount(true);
            }

            setSubscriptionDetailsModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Students;

const MainComponent = ({
  setDropdownSettings,
  dropdownSettings,
  editable,
  setEditable,
  status,
  setStatus,
  selectedStudents,
  setSelectedStudents,
  setMoreSettings,
  handleSelectAll,
  handleInviteClick,
  students,
  setDeleteStudentsModal,
}: {
  setDropdownSettings: (val: string) => void;
  dropdownSettings: string;
  editable: boolean;
  setEditable: (val: boolean) => void;
  status: "Invitation Pending" | "Registration Pending" | "Active" | "Inactive";
  setStatus: (
    val: "Invitation Pending" | "Registration Pending" | "Active" | "Inactive"
  ) => void;
  selectedStudents: string[];
  setSelectedStudents: React.Dispatch<React.SetStateAction<string[]>>;
  handleSelectAll: () => void;
  setMoreSettings: (val: string) => void;
  students: IInvitedStudent[];
  handleDeleteInvitations: () => void;
  handleInviteClick: () => void;
  setDeleteStudentsModal: (val: boolean) => void;
}) => {
  // const activeStudents = students.filter(
  //   (student) => !INACTIVE_STATUSES.includes(student.status)
  // );
  // const inactiveStudents = students.filter((student) =>
  //   INACTIVE_STATUSES.includes(student.status)
  // );

  const handleCheckboxChange = (email: string) => {
    setSelectedStudents((prevSelected: string[]) =>
      prevSelected.includes(email)
        ? prevSelected.filter((el) => el !== email)
        : [...prevSelected, email]
    );
  };

  const toggleSelectAll = () => {
    const allSelected =
      students.filter((student) =>
        status === "Active"
          ? student.status === "Active"
          : INACTIVE_STATUSES.includes(student.status)
      ).length === selectedStudents.length;

    allSelected ? setSelectedStudents([]) : handleSelectAll();
  };

  const filteredStudents = students.filter((student) =>
    status === "Active"
      ? student.status === "Active"
      : INACTIVE_STATUSES.includes(student.status)
  );

  return (
    <div className="flex flex-1 flex-col pb-[120px] lg:pb-24 lg:h-svh lg:relative">
      {dropdownSettings && (
        <div
          onClick={() => {
            setDropdownSettings?.("");
          }}
          className=" bg-light-grey-7 fixed bottom-0 left-0 right-0 top-0 z-[60] flex h-svh w-full items-center justify-center"
        />
      )}
      <GridTitle title="Students" />
      <GridLGHidden>
        <Typography type="Body2" className="text-main-gray mt-8">
          Manage students accounts.
        </Typography>
      </GridLGHidden>
      {students.length !== 0 && (
        <div className="flex items-center gap-24 mb-24 lg:mt-0 mt-24">
          <Typography
            type="Body3"
            className={`cursor-pointer ${
              status === "Active" ? "text-primary" : ""
            }`}
            onClick={() => {
              setStatus("Active");
              setSelectedStudents([]);
            }}
          >
            Active
          </Typography>
          <div className="bg-light-grey-10 h-10 w-[1px]"></div>
          <Typography
            type="Body3"
            className={`cursor-pointer ${
              status !== "Active" ? "text-primary" : ""
            }`}
            onClick={() => {
              setStatus("Inactive");
              setSelectedStudents([]);
            }}
          >
            Pending
          </Typography>
        </div>
      )}
      <div className="lg:pr-24 lg:max-h-[78svh] lg:flex-1 bg-white border mt-24 lg:mt-0 border-light-grey-10 pt-24 pb-32 lg:py-24 rounded-20 w-full">
        <div className=" h-full px-24 overflow-y-auto lg:pb-80">
          {(students.length === 0 ||
            students.filter((el) =>
              status === "Active"
                ? el.status === "Active"
                : el.status !== "Active"
            ).length === 0) && (
            <div>
              <Typography type="Body">The list is empty</Typography>
              {students.length === 0 && (
                <Typography type="Body2" className="text-main-gray mt-12">
                  No students have been invited yet.
                </Typography>
              )}
            </div>
          )}
          { students.length !== 0 &&
            students.filter((el) =>
              status === "Active"
                ? el.status === "Active"
                : el.status !== "Active"
            ).length !== 0 && (
              <div className="flex flex-col gap-24">
                <div className="flex items-center justify-between">
                  <Typography type="Body2">
                    {status === "Active" ? "Active" : "Pending"}
                  </Typography>
                  <GridSMHidden>
                    <div className="flex items-center gap-24">
                      {editable && (
                        <Typography
                          type="Body2"
                          className="cursor-pointer"
                          onClick={toggleSelectAll}
                        >
                          {filteredStudents.length === selectedStudents.length
                            ? "Unselect all"
                            : "Select all"}
                        </Typography>
                      )}
                      <div
                        className="w-40 h-40 flex justify-center items-center cursor-pointer"
                        onClick={() => {
                          setEditable(!editable);
                        }}
                      >
                        <img src={editable ? EditIconBlue : EditIcon} alt="" />
                      </div>
                    </div>
                  </GridSMHidden>
                </div>
                {students
                  .filter((el) =>
                    status === "Active"
                      ? el.status === "Active"
                      : el.status !== "Active"
                  )
                  .map((el) => {
                    return <StudentListItem
                      key={el.email}
                      handleCheckboxChange={() =>
                        handleCheckboxChange(el.email)
                      }
                      email={el.email}
                      name={el.firstName ? el.firstName + " " + el.lastName : el.email}
                      editable={editable}
                      status={el.status}
                      selectedStudents={selectedStudents}
                      setDropdownSettings={setDropdownSettings}
                      dropdownSettings={dropdownSettings}
                      setMoreSettings={setMoreSettings}
                      setSelectedStudents={setSelectedStudents}
                      setDeleteStudentsModal={setDeleteStudentsModal}
                    />
})}
              </div>
            )}
        </div>
      </div>
      {editable && (
        <div className="z-[72] h-64 bg-primary rounded-2xl px-24 py-12 flex justify-between items-center lg:absolute w-full relative bottom-24 lg:bottom-10">
          <Typography type="Body2" className="text-white">
            {selectedStudents.length}{" "}
            {selectedStudents.length === 1 ? "student" : "students"} selected
          </Typography>
          {selectedStudents.length !== 0 && 
            <div className="flex items-center gap-24">
              {status !== "Active" &&  
              <div
              className="bg-white rounded-full flex justify-center items-center w-40 h-40 cursor-pointer"
              onClick={() => {
                handleInviteClick();
              }}
            >
              <img src={ResendIcon} alt="" />
            </div>}
              
              <div
                className="bg-white rounded-full flex justify-center items-center w-40 h-40 cursor-pointer"
                onClick={() => setDeleteStudentsModal(true)}
              >
                <img src={TrashIcon} alt="" />
              </div>
            </div>
          }
        </div>
      )}
    </div>
  );
};

const MoreOptionsModal = ({
  isOpen,
  setOpen,
  onClickDelete,
  onResendClick,
  status
}: {
  isOpen: boolean;
  setOpen: (val: boolean) => void;
  onResendClick: () => void;
  onClickDelete: () => void;
  status: "Invitation Pending" | "Registration Pending" | "Active" | "Inactive";
}) => {
  return (
    <BottomSheetModal
      isOpen={isOpen}
      setOpen={setOpen}
      onClose={() => setOpen(false)}
      darkBackground={true}
    >
      <div className="mx-16 mb-20 flex flex-col gap-12">
        <div
          className="flex items-center gap-12 rounded-2xl bg-white p-16"
          onClick={onClickDelete}
        >
          <img src={TrashIcon} alt="" />
          <div>
            <Typography type="Body2">Remove user</Typography>
          </div>
        </div>
        {status !== "Active" && 
         <div
         className="flex items-center gap-12 rounded-2xl bg-white p-16"
         onClick={() => {
           onResendClick?.();
         }}
       >
         <img src={ResendIconBlack} alt="" />
         <div>
           <Typography type="Body2">Resend invitation</Typography>
         </div>
       </div>}
       
      </div>
    </BottomSheetModal>
  );
};
